.swiper {
    max-width: 620px;
    margin: 0 auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    max-height: 410px;
    max-width: 620px;
}

.mySwiper {
    height: 160px;
    box-sizing: border-box;
    padding: 10px 0;
}

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.swiper-button-next::after, .swiper-button-prev::after {
    content: '' !important;
}
.swiper-button-next {
    background-image: url(../assets/icons/next.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
}
.swiper-button-prev {
    background-image: url(../assets/icons/prev.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
}

@media (max-width: 768px) {
    .mySwiper2, .mySwiper {
        max-width: calc(100vw - 40px - 120px) !important;
    }
    .mySwiper {
        height: 100%;
    }

}

@media (max-width: 480px) {
    .mySwiper2, .mySwiper {
        max-width: calc(100vw - 40px - 60px) !important;
    }
    .mySwiper2 {
        max-height: 250px;
    }
}
@media (max-width: 320px) {
    .mySwiper2 {
        max-height: 200px;
    }
    .swiper-slide img {
        object-fit: contain;
    }
}
