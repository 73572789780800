body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Apercu Pro',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant: normal;
}

* {
    font-family: 'Gymkhana', sans-serif;
    box-sizing: border-box;
    text-decoration: none;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    font-variant: normal;
}

@font-face {
    font-family: 'Blazma';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Blazma-Regular.woff2") format("woff2"),
    url("./fonts/Blazma-Regular.woff") format("woff");
}
@font-face {
    font-family: 'Blazma';
    font-style:  italic;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Blazma-Italic.woff2") format("woff2"),
    url("./fonts/Blazma-Italic.woff") format("woff");
}
@font-face {
    font-family: 'Gymkhana';
    font-style:  normal;
    font-weight: 400;
    src: url("./fonts/gymkhana-bk.ttf") format("truetype");
}
