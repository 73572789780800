//colors
$dark-blue: #000D61;
$blue: #074CED;
$blue-active: #0642d9;
$dark: #0C1133;
$dark-background: #141a36;
$white: #FFF;

//typography
//h1
$h1-font-size: 55px;
$h1-font-weight: 400;
$h1-line-height: 63px;
$h1-letter-spacing: -0.05em;

@mixin h1() {
    font-family: 'Blazma', sans-serif;
    font-weight: $h1-font-weight;
    font-size: $h1-font-size;
    line-height: $h1-line-height;
    letter-spacing: $h1-letter-spacing;
    text-transform: uppercase;
    font-style: italic;

    @media (max-width: 992px) {
        font-size: 40px;
        line-height: 48px;
    }

    @media (max-width: 480px) {
        font-size: 32px;
        line-height: 37px;
    }
}

//h2
$h2-font-size: 32px;
$h2-font-weight: 400;
$h2-line-height: 37px;

@mixin h2() {
    font-weight: $h2-font-weight;
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    font-style: italic;
    text-transform: uppercase;

    @media (max-width: 992px) {
        font-size: 24px;
        line-height: 30px;
    }
    @media (max-width: 480px) {
        font-size: 20px;
        line-height: 22px;
    }
}

//h3
$h3-font-size: 20px;
$h3-font-weight: 400;
$h3-line-height: 24px;

@mixin h3() {
    font-weight: $h3-font-weight;
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    text-transform: uppercase;

    @media (max-width: 992px) {
        font-size: 18px;
        line-height: 22px;
    }
    @media (max-width: 480px) {
        font-size: 16px;
        line-height: 19px;
    }
}

//h4
$h4-font-size: 18px;
$h4-font-weight: 400;
$h4-line-height: 22px;

@mixin h4() {
    font-weight: $h4-font-weight;
    font-size: $h4-font-size;
    line-height: $h4-line-height;

    @media (max-width: 992px) {
        font-size: 16px;
        line-height: 19px;
    }
    @media (max-width: 320px) {
        font-size: 14px;
        line-height: 16px;
    }
}

//p
$p-font-size: 16px;
$p-font-weight: 400;
$p-line-height: 19px;

@mixin p() {
    font-weight: $p-font-weight;
    font-size: $p-font-size;
    line-height: $p-line-height;

    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 16px;
    }
}

//text
$text-font-size: 14px;
$text-font-weight: 400;
$text-line-height: 17px;

@mixin text() {
    font-weight: $text-font-weight;
    font-size: $text-font-size;
    line-height: $text-line-height;

    @media (max-width: 992px) {
        font-size: 12px;
        line-height: 14px;
    }
}

@mixin flexible() {
    display: flex;
    align-items: center;
}

@mixin absoluteCenter() {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

@mixin scroll() {


}
